export const environment = {
	production: false,
	API_GENERAL_URL: 'https://clip-api.3cubehealth.com/',
	MANTRA_FINGERPRINT_DEVICE_URL:'https://localhost:8003/mfs100/',
	defaultToMail:"yogesh.kapase@technicious.in",
	FIX_TOKEN:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEzMDg1MDYyLCJpYXQiOjE2ODE1NDkwNjIsInVzZXJfaWQiOjEsImp0aSI6IjhjMTM0YWE2ZTkxZjRlMzg5ZGQ0ZTM1ODkxZTY4Y2FhIn0.B78GgwNNwIAOVDbEnqTW8SLTrOsMBHcct-7RRrNzsf8",
	owners:[
		{name:"dr_tiwari",id:1},
		{name:"drsc",id:5},
		{name:"philips",id:7},
		{name:"Kanwar",id:8},
		{name:"3Cube",id:9},
		{name:"chaudhari",id:10},
		{name:"sahijwala",id:3},
		{name:"pol",id:11},
	],
	showAccess:["Doctor","Staff","Consultant","Pathologist","Radiologist","Cardiologist","Dentist","Accountant","Network","Orthopedic","Physiotherapist"],
	repTypeUSDId:20,
	repTypeUSDId1:21
};