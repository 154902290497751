import { BrowserModule } from '@angular/platform-browser';
import { TagInputModule } from 'ngx-chips';
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RefreshComponent } from './refresh/refresh.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { InPlaceEditorModule } from '@syncfusion/ej2-angular-inplace-editor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RecoverComponent } from './recover/recover.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UrlSerializerService } from './url-serializer.service';
import { UrlSegment, UrlSerializer } from '@angular/router';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticatedHttpService } from './authenticated-http.service';
import { SafePipe } from './shared/pipes/safe.pipe';
import { SharedPipesModule } from './shared-pipes.module';
import { DataTablesModule } from 'angular-datatables';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { WebcamModule } from 'ngx-webcam';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgToggleModule } from 'ng-toggle-button';
import { FaceLoginComponent } from './face-login/face-login.component';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { CandidateDetailsComponent } from './appointments/candidate-details/candidate-details.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		RefreshComponent,
		RecoverComponent,
		ResetPasswordComponent,
		SafePipe,
		FaceLoginComponent,
		CandidateDetailsComponent
	],
	imports: [
		CommonModule,
		TagInputModule,
		ReactiveFormsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NgSelectModule,
		FormsModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		NgbModule,
		NgbPaginationModule,
		NgbAlertModule,
		RichTextEditorAllModule,
		InPlaceEditorModule,
		NgxFileDropModule,
		SharedPipesModule,
		DataTablesModule,
		DateInputsModule,
		WebcamModule,
		SignaturePadModule,
		NgToggleModule.forRoot()
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: UrlSerializer,
			useClass: UrlSerializerService
		},
		AsyncPipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticatedHttpService,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);