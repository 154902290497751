import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
	Sentry.init({
		dsn: "https://c6365477f7df4bd7b2a1a807910d9571@o4505345052246016.ingest.sentry.io/4505368494604288",
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [],
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],
		tracesSampleRate: 1.0
	});
	enableProdMode();
}else{
	if(window.location.href.indexOf('localhost')==-1){
		Sentry.init({
			dsn: "https://c6365477f7df4bd7b2a1a807910d9571@o4505345052246016.ingest.sentry.io/4505368494604288",
			integrations: [
				new Sentry.BrowserTracing({
					tracePropagationTargets: [],
					routingInstrumentation: Sentry.routingInstrumentation,
				}),
				/* new Sentry.Replay(), */
			],
			tracesSampleRate: 1.0,
			/* replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0 */
		});
	}
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.error(err));