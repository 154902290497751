<video #bgVideo autoplay loop muted src="../assets/videos/CLIP3_3_NEW_BG.mp4"></video>
<form [formGroup]="moduleForm" (ngSubmit)="submitForm()" #modform="ngForm">
	<div *ngIf="loadershow" class="loader text-center">
		<img src="../assets/img/cc_loader.gif" />
	</div>
	<div class="container container-sm animated fadeInDown">
		<div style="background-color: #e6eff5;margin-top: 100px;" class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
			<div class="text-center" style="margin-top: 30px;">
				<img src="../assets/img/logo_new.png" alt="Image" width="130px"/>
			</div>
			<fieldset class="mt20">
				<div class="col-sm-12 form-group" *ngIf="!showOTPField">
					<div class="input-group m-b">
						<span class="input-group-addon labelColor1"><i class="fa fa-user" aria-hidden="true"></i></span>
						<input type="text" placeholder="Enter your email id" style="border: 1px solid #cccccc;" class="form-control input_focus input-xs" id="emailId" formControlName="emailId"/>
					</div>
				</div>
				<p *ngIf="!showOTPField && f.emailId.invalid && (modform.submitted || f.emailId.touched || f.emailId.dirty)" style="color: maroon;text-align: center;">
					<span *ngIf="f.emailId.errors?.required">
						Please enter email id
					</span>
				</p>
				<div class="col-sm-12 form-group mt-lg" *ngIf="showOTPField">
					<div class="input-group m-b">
						<span class="input-group-addon labelColor1"><i class="fa fa-lock" aria-hidden="true"></i></span>
						<input type="text" placeholder="Enter OTP" style="border: 1px solid #cccccc;" class="form-control input_focus input-xs" id="otp" formControlName="otp" />
					</div>
				</div>
				<p *ngIf="showOTPField" style="text-align: center;">Please enter OTP received at your email</p>
				<div class="col-sm-12 form-group mt-lg text-center">
					<button id="btnSubmit" type="submit" class="btn btn-info">Submit</button>
				</div>
				<div class="col-sm-12 text-center text-black mt-lg" style="font-size: 11px;">&nbsp;&copy; {{year}}&nbsp;-&nbsp;<a href="http://3cubeservices.com/" target="_blank" class="text-black">3 Cube Services</a></div>
				<div class="col-sm-12 form-group text-center">
					<a href="mailto:info@3cubeservices.com" style="color:#000; font-weight:inherit; ">info@3cubeservices.com </a>
					|
					<a href="../assets/files/termsofservicedisclaimer.pdf" style="text-align:right; color:#333; text-decoration:underline;" target="_blank">Terms of Services</a>
				</div>
			</fieldset>
			<div *ngIf="loginErrorshow" role="alert" type="danger" class="alert ng-isolate-scope alert-danger alert-dismissible">
				<button id="btnCloseErrorMsg" type="button" class="close">
					<span class="sr-only">Close</span>
				</button>
				<div class="text-center">
					<span class="ng-binding ng-scope">{{loginFormErr}}</span>
				</div>
			</div>
		</div>
	</div>
</form>