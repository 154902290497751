import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, concat } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	baseURL = environment.API_GENERAL_URL;
	fingerDevice = environment.MANTRA_FINGERPRINT_DEVICE_URL;

	dataByEvent: EventEmitter<string> = new EventEmitter<string>();
	ownerChangeEvent: EventEmitter<any> = new EventEmitter();

	constructor(private httpClient: HttpClient) { }

	public getOwnerName() {
		let dr_id: any = localStorage.getItem('OwnerId');
		for (let i = 0; i < environment.owners.length; i++) {
			if (dr_id == environment.owners[i].id) {
				return environment.owners[i].name;
			}
		}
		return "";
	}

	public getDashboardURL() {
		let dr_id: any = localStorage.getItem('OwnerId');
		let dr_name: any = "";
		for (let i = 0; i < environment.owners.length; i++) {
			if (dr_id == environment.owners[i].id) {
				dr_name = environment.owners[i].name;
			}
		}
		if (dr_name == "3Cube") {
			return "3c-dashboard";
		} else {
			return "dashboard";
		}
	}

	public getDocInvURL(page: String) {
		let dr_id: any = localStorage.getItem('OwnerId');
		let dr_name: any = "";
		for (let i = 0; i < environment.owners.length; i++) {
			if (dr_id == environment.owners[i].id) {
				dr_name = environment.owners[i].name;
			}
		}
		if (page == "medical_examination") {
			if (dr_name == "philips") {
				return "medical_examination_6";
			} else if (dr_name == "pol") {
				return "medical_examination_5";
			} else if (dr_name == "sahijwala") {
				return "medical_examination_4";
			} else if (dr_name == "drsc") {
				return "medical_examination_3";
			} else if (dr_name == "chaudhari") {
				return "medical_examination_2";
			} else {
				return "medical_examination_1";
			}
		} else {
			if (dr_name == "philips") {
				return "investigation_6";
			} else if (dr_name == "pol") {
				return "investigation_5";
			} else if (dr_name == "sahijwala") {
				return "investigation_4";
			} else if (dr_name == "drsc") {
				return "investigation_3";
			} else if (dr_name == "chaudhari") {
				return "investigation_2";
			} else {
				return "investigation_1";
			}
		}
	}

	public getTreatmentPageURL() {
		let dr_id: any = localStorage.getItem('OwnerId');
		let dr_name: any = "";
		for (let i = 0; i < environment.owners.length; i++) {
			if (dr_id == environment.owners[i].id) {
				dr_name = environment.owners[i].name;
			}
		}
		if (dr_name == "3Cube") {
			return "treatment-cube";
		} else {
			return "treatment";
		}
	}

	public loginAPI(user: any) {
		var data = { username: user.email, password: user.password }
		return this.httpClient.post(this.baseURL + "api/signin/", data);
	}

	public generate_qr(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/generate_qr", data, { responseType: "blob" });
	}

	public verifyGoogleAuthOTP(params: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/verify_otp", options);
	}

	public reset2fa(data: any) {
		var token = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token!);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/reset2fa", data, { headers: headers });
	}

	public logout() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/signOut/", { headers: headers });
	}

	updateClinicName(name: string) {
		this.dataByEvent.emit(name);
	}

	updateOwnerName() {
		this.ownerChangeEvent.emit();
	}

	public sendResetPasswordLink(data: any) {
		return this.httpClient.post(this.baseURL + "api/sendResetPasswordLink/", data);
	}

	public setNewPassowrd(data: any) {
		return this.httpClient.post(this.baseURL + "api/setPassowrd/", data);
	}

	public checkLinkExpiry(data: any) {
		return this.httpClient.post(this.baseURL + "api/checkLinkExpiry/", data);
	}

	public resetPassword(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changePassword/", data, { headers: headers });
	}

	public allUserAccessType() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allUserAccessType", { headers: headers });
	}

	public allAcrediations() {
		var token: any = localStorage.getItem('token');
		var OwnerId: any = localStorage.getItem('OwnerId');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: { OwnerId: OwnerId } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/allAcrediations", options);
	}

	public createAcrediations(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createAcrediations", data, { headers: headers });
	}

	public getAcrediationsDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editAcrediationsData", data, { headers: headers });
	}

	public deleteAcrediations(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteAcrediations", data, { headers: headers });
	}

	public getAllClinicUsers(data: any) {
		var token: any = localStorage.getItem('token');
		var clinic: any = localStorage.getItem('clinic');
		if (clinic) {
			data.clinic = clinic;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allClinicUsers", data, { headers: headers });
	}

	public getAllClinicUsersWithoutClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allClinicUsers", data, { headers: headers });
	}

	public getClinicUserDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editClinicUsers", data, { headers: headers });
	}

	public createClinicUsers(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createClinicUsers", data, { headers: headers });
	}

	public deleteClinicUser(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteClinicUsers", data, { headers: headers });
	}

	public getAllAccessModules() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allAccessModules", { headers: headers });
	}

	public getAllCountry() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allCountry", { headers: headers });
	}

	public getStatesByCountry(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allStatesByCountry", data, { headers: headers });
	}

	public getCitiesByState(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allCitiesByState", data, { headers: headers });
	}

	public getAllClinics(data: any) {
		var token: any = localStorage.getItem('token');
		var clinic: any = localStorage.getItem('clinic');
		if (clinic) {
			data.clinic = clinic;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allClinic", data, { headers: headers });
	}

	public getAllClinicsForProfileImport(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allClinic", data, { headers: headers });
	}

	public createClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createClinic", data, { headers: headers });
	}

	public getClinicDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editClinic", data, { headers: headers });
	}

	public deleteClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteClinic", data, { headers: headers });
	}

	public getOwnerDoctorDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getBasicInfoOfDoctor", data, { headers: headers });
	}

	public updateOwnerDoctorDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editBasicInfoOfDoctor", data, { headers: headers });
	}

	public deleteCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteCompany", data, { headers: headers });
	}

	public getCompanyDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editCompany", data, { headers: headers });
	}

	public saveCompanyDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createCompany", data, { headers: headers });
	}

	public deleteVessel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteVessel", data, { headers: headers });
	}

	public getVesselDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editVessel", data, { headers: headers });
	}

	public saveVesselDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createVessel", data, { headers: headers });
	}

	public getAllCompany() {
		var token: any = localStorage.getItem('token');
		var OwnerId: any = localStorage.getItem('OwnerId');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allCompany", { OwnerId: OwnerId }, { headers: headers });
	}

	public deleteProfile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteProfile", data, { headers: headers });
	}

	public getProfileDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editProfile", data, { headers: headers });
	}

	public saveProfileDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createProfile", data, { headers: headers });
	}

	public updateAgeGroup(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editAge", data, { headers: headers });
	}

	public allReportsAndTests(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allReportsAndTests", data, { headers: headers });
	}

	public getAllProfiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allProfile", data, { headers: headers });
	}

	public getProfilesByCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getProfilesByCompany", data, { headers: headers });
	}

	public getProfilesByCompanies(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ProfilesCompany", data, { headers: headers });
	}

	public getAllFitnessStatus() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allPateState", { headers: headers });
	}

	public getAllPurpose() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allTypeOfMedical", { headers: headers });
	}

	public getAllStatementType() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allTypeOfInvoices", {}, { headers: headers });
	}
	public deleteStatement(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/removeInvoice", data, { headers: headers });
	}
	public ViewStatement(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/listOfInvoiceCand", data, { headers: headers });
	}

	public createReferenceNo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/newInvoiceNum", data, { headers: headers });
	}

	public getAppoitmentsExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/appoinmentListExcel", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public updateAppointmentDateTime(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateAppointmentDateTime", data, { headers: headers });
	}

	public updateReportStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateReportStatus", data, { headers: headers });
	}

	public getAllVessels(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allVessel", data, { headers: headers });
	}

	public getAllNationality() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append("Authorization", token);
		}
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allNationality", { headers: headers });
	}

	public getAllRanks(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allRank", { params: new HttpParams(httpParams), headers: headers });
	}

	public allSubDepartment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/allSubDepartment", { params: new HttpParams(httpParams), headers: headers });
	}

	public createAppointment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createAppointment", data, { headers: headers });
	}

	public createStatement(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createNewInvoice", data, { headers: headers });
	}
	public addCandidateTostatement(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createNewCandInvoice", data, { headers: headers });
	}

	public getAppointmentDetails(data: any) {
		var token: any = localStorage.getItem('token');
		if (!token) {
			token = "Bearer " + environment.FIX_TOKEN;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editAppointment", data, { headers: headers });
	}

	public deleteAppointment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteAppointment", data, { headers: headers });
	}

	public reopenAppointment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/openCancelAppt", data, { headers: headers });
	}

	public checkUniquePassportNo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/checkPassportNo", data, { headers: headers });
	}

	public uploadDigitalSignature(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/uploadDigitalSignature", data, { headers: headers });
	}

	public uploadDigitalSignatureOfDoctor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/uploadDigitalSignatureOfDoctor", data, { headers: headers });
	}

	public uploadCandidatePicture(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/uploadCandidatePicture", data, { headers: headers });
	}

	public allTrackingCategory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allTrackingCategory", data, { headers: headers });
	}

	public getDefaultValues(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getDefaultValues", data, { headers: headers });
	}

	public appointmentReviewAndMedicalCertificate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/appointmentReviewAndMedicalCertificate", data, { headers: headers });
	}

	public getAppointmentReviewAndMedicalCertificate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getAppointmentReviewAndMedicalCertificate", data, { headers: headers });
	}

	public setDefaultValue(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/setDefaultValue", data, { headers: headers });
	}

	public updateCandidateTracking(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateCandidateTracking", data, { headers: headers });
	}

	public updateInClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateInClinic", data, { headers: headers });
	}

	public testToBedone(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/testToBedone", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public generateLabel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/generateLabel", { params: new HttpParams(httpParams), headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public generateLabelWithBarcode(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/generateBarcode", { params: new HttpParams(httpParams), headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getCandidateBillDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getCandidateBill", data, { headers: headers });
	}

	public updateInvoiceDate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateInvoiceDate", data, { headers: headers });
	}

	public saveCandidateBill(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveCandidateBill", data, { headers: headers });
	}

	public getBasicDetailsByPassportNo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getBasicDetailsByPassportNo", data, { headers: headers });
	}

	public getQuetionsAndFamilyHistory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getQuetionsAndFamilyHistory", data, { headers: headers });
	}

	public saveQuestions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveQuestions", data, { headers: headers });
	}

	public saveFamilyHistory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveFamilyHistory", data, { headers: headers });
	}

	public getSystemicExaminationQuestions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getSystemicExaminationQuestions", data, { headers: headers });
	}

	public saveSystemicExamination(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveSystemicExamination", data, { headers: headers });
	}

	public getClinicalExaminationQuestions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getClinicalExaminationQuestions", data, { headers: headers });
	}

	public saveClinicalExamination(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveClinicalExamination", data, { headers: headers });
	}

	public getOpthalmicExaminationQuestions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getOpthalmicExaminationQuestions", data, { headers: headers });
	}

	public saveOpthalmicExamination(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveOpthalmicExamination", data, { headers: headers });
	}

	public getInformationQuestions(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getInformationQuestions", data, { headers: headers });
	}

	public saveInformation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveInformation", data, { headers: headers });
	}

	public getInvestigation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getInvestigation", data, { headers: headers });
	}

	public saveInvestigation(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveInvestigation", data, { headers: headers });
	}

	public uploadMedExamFile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/fileUpload", data, { headers: headers });
	}

	public getFiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getFiles", data, { headers: headers });
	}

	public deleteFile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteFile", data, { headers: headers });
	}

	public addCandidateToClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/AddCandidateToClinic", data, { headers: headers });
	}

	public checkCandidateInClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CheckCandidateInClinic", data, { headers: headers });
	}

	public getCaseHistory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getCaseHistory", data, { headers: headers });
	}

	public getCaseDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getCaseDetails", data, { headers: headers });
	}

	public saveTreatmentDoctor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentDoctor", data, { headers: headers });
	}

	public saveTreatmentPathologist(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentPathologist", data, { headers: headers });
	}

	public saveTreatmentHistory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentHistory", data, { headers: headers });
	}

	public saveTreatment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatment", data, { headers: headers });
	}

	public saveTreatmentDrComments(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentDrComments", data, { headers: headers });
	}

	public saveTreatmentNotes(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentNotes", data, { headers: headers });
	}

	public saveTreatmentStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentStatus", data, { headers: headers });
	}

	public saveTreatmentfollowup(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentfollowup", data, { headers: headers });
	}

	public updatePaymentStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updatePaymentStatus", data, { headers: headers });
	}

	public getExcelAccountList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getExcelAccountList", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getExcelAccountList_drsc(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getExcelAccountList_drsc", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public checkUniqueIndosNo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/checkIndosNo", data, { headers: headers });
	}

	public getBasicDetailsByIndosNo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/detailsByIndosNo", data, { headers: headers });
	}

	public candidateBillPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateBillPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public accountsCandidateBillPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/accountsCandidateBillPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public accountsCompanyBillPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/accountsCompanyBillPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getTestAndQuestionMaster(data: any): Observable<any[]> {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let response1 = this.httpClient.post(this.baseURL + "api/getTestmasterList", data, { headers: headers });
		let response2 = this.httpClient.post(this.baseURL + "api/getQuestionmasterList", {}, { headers: headers });
		let response3 = this.httpClient.post(this.baseURL + "api/getUserdetailList", {}, { headers: headers });
		let response4 = this.httpClient.post(this.baseURL + "api/showAllResults", {}, { headers: headers });
		return forkJoin([response1, response2, response3, response4]);
	}

	public createReports(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createReports", data, { headers: headers });
	}

	public getImages(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getImages", data, { headers: headers });
	}

	public getReportPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createPdfOnReports", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public listOfInvoiceCand(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/listOfInvoiceCand", data, { headers: headers });
	}

	public removeCandFromInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/removeCandFromInvoice", data, { headers: headers });
	}

	public getInvoiceExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getExcelInvoiceList", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public showEditDataInvoive(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/showEditDataInvoive", data, { headers: headers });
	}

	public editBillingStatement(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editBillingStatement", data, { headers: headers });
	}

	public viewAllDataOfInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/viewAllDataOfInvoice", data, { headers: headers });
	}

	public getReportCats(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getReportCats", data, { headers: headers });
	}

	public addReportCateg(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/addReportCateg", data, { headers: headers });
	}

	public getDoctorList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getDoctorList", data, { headers: headers });
	}

	public saveReportData(data: any, isCreate: boolean) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		if (isCreate) {
			return this.httpClient.post(this.baseURL + "api/addNewReport", data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "api/editReport", data, { headers: headers });
		}
	}

	public getReportDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/showDetailsEditReport", data, { headers: headers });
	}

	public printPDFforInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/printPDFforInvoice", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public doRepoLive(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/doRepoLive", data, { headers: headers });
	}

	public allMainDoctors() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allMainDoctors", {}, { headers: headers });
	}

	public getMainDoctorDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editMainDoctor", data, { headers: headers });
	}

	public saveMainDoctorDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/createMainDoctor", data, { headers: headers });
	}

	public getDashboardData1(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getDashboardData1", data, { headers: headers });
	}

	public getDashboardData2(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getDashboardData2", data, { headers: headers });
	}

	public getDashboardData3(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getDashboardData3", data, { headers: headers });
	}

	public getDashboardData4(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getDashboardData4", data, { headers: headers });
	}

	public captureFingerPrint(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.fingerDevice + "capture", data, { headers: headers });
	}

	public addFingerPrint(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/addFingerPrint", data, { headers: headers });
	}

	public verifyFingerPrint(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/verifyFingerPrint", data, { headers: headers });
	}

	public getFingerPrint(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getFingerPrint", data, { headers: headers });
	}

	public deleteFingerPrint(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteFingerPrint", data, { headers: headers });
	}

	public verifyFingerPrintFromDevice(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.fingerDevice + "match", data, { headers: headers });
	}

	public updateVerification(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateVerification", data, { headers: headers });
	}

	public TreatmentBillList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/TreatmentBillList", data, { headers: headers });
	}

	public updateInvoiceStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveInvoiceStatusTreatmentBill", data, { headers: headers });
	}

	public updatePaymentStatusTreatment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/savePaymentStatusTreatmentBill", data, { headers: headers });
	}

	public treatmentListExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/treatmentListExcel", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getTreatmentCandidateBill(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTreatmentCandidateBill", data, { headers: headers });
	}

	public saveTreatmentCandidateBill(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentCandidateBill", data, { headers: headers });
	}

	public candidateTreatmentBillPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateTreatmentBillPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public candidateTreatmentBillPdfInUSD(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateTreatmentBillPdfInUSD", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public candidateConsultantTreatmentBillPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateConsultantTreatmentBillPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getCategoryWiseReports(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/viewCategoriesReports", data, { headers: headers });
	}

	public verifyUser(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/verifyUser/", data, { headers: headers });
	}

	public postQRDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/verifyQrCode", data, { headers: headers });
	}

	public getLabDocumentsList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getLabDocumentsList", data, { headers: headers });
	}

	public getTestMaster(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestmasterList", data, { headers: headers });
	}

	public getTestmasterListNew(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestmasterListNew", data, { headers: headers });
	}

	public buildLabReport(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/buildLabReport", data, { headers: headers });
	}

	public datailsLabRepo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/datailsLabRepo", data, { headers: headers });
	}

	public addEditLabReport(data: any, isEdit: boolean) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		if (isEdit) {
			return this.httpClient.post(this.baseURL + "api/editLabReport", data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "api/addLabReport", data, { headers: headers });
		}
	}

	public uploadMultipleFiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		let requests: any = [];
		for (let i = 0; i < data.length; i++) {
			requests.push(this.httpClient.post(this.baseURL + "api/fileUpload", data[i], { headers: headers }));
		}
		return concat(...requests);
	}

	public getDocTestmasterList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getDocTestmasterList", data, { headers: headers });
	}

	public changeTestDataType(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeTestDataType", data, { headers: headers });
	}

	public changeTestRefMin(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeTestRefMin", data, { headers: headers });
	}

	public changeTestRefMax(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeTestRefMax", data, { headers: headers });
	}

	public changeTestUnit(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeTestUnit", data, { headers: headers });
	}

	public changeTestDefaultValue(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeTestDefaultValue", data, { headers: headers });
	}

	public uploadHeaderFooterHologram(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/uploadHeaderFooter", data, { headers: headers });
	}

	public getHeaderFooter(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/showHeaderFooter", data, { headers: headers });
	}

	public changeMinMaxString(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/changeMinMaxString", data, { headers: headers });
	}

	public getAllVaccine() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getAllVaccine", {}, { headers: headers });
	}

	public addEditVaccineReport(data: any, isEdit: boolean) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		if (isEdit) {
			return this.httpClient.post(this.baseURL + "api/editVaccineReport", data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "api/addVaccineReport", data, { headers: headers });
		}
	}

	public detailsVaccineRepo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/detailsVaccineRepo", data, { headers: headers });
	}

	public deleteLabReport(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteLabReport", data, { headers: headers });
	}

	public createAppointment_tempUnfit(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createAppointment_tempUnfit", data, { headers: headers });
	}

	public updateFollowUpDateAndNotes(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateFollowUpDateAndNotes", data, { headers: headers });
	}

	public getAllPageAccessModules() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allCubeAccess", { headers: headers });
	}

	public getVesselsByCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allVesselByCompany", data, { headers: headers });
	}

	public reportDueOn(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/reportDueOn", data, { headers: headers });
	}

	public addNewResult(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/addNewResult", data, { headers: headers });
	}

	public showAllReasons(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/showAllReasons", data, { headers: headers });
	}

	public addNewReason(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/addNewReason", data, { headers: headers });
	}

	public getApptRunRate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptRunRate", data, { headers: headers });
	}

	public companyWiseAppointments(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CompanyWiseAppointments", data, { headers: headers });
	}

	public getTopCompanies(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/TopCompanies", data, { headers: headers });
	}

	public getApptCounts(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptCounts", data, { headers: headers });
	}

	public getApptCountStatusWise(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptStatus", data, { headers: headers });
	}

	public getApptCountTypeWise(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptByType", data, { headers: headers });
	}

	public getApptCountReasonWise(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/TopReasonsForUnfit", data, { headers: headers });
	}

	public deleteWatermark(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteWatermark", data, { headers: headers });
	}

	public updateBillPaymentStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateBillPaymentStatus", data, { headers: headers });
	}

	public updateBillInvoiceStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateBillInvoiceStatus", data, { headers: headers });
	}

	public updateInvoiceRemarks(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateInvoiceRemarks", data, { headers: headers });
	}

	public getStatementsExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/statementListExcel", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getAccountantList(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getAccountantList", data, { headers: headers });
	}

	public candidateReceiptPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateReceiptPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getReportsByDoctor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/reportsWithoutPagination", data, { headers: headers });
	}

	public copyReportCode(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/copyReportCode", data, { headers: headers });
	}

	public getTestcategory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestcategory  ", data, { headers: headers });
	}

	public getTestListCatWise(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestListCatWise  ", data, { headers: headers });
	}

	public customReport(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/customReport", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public copyReportCodePageWise(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/copyReportCodePageWise", data, { headers: headers });
	}

	public deleteUnfitReason(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteUnfitReason", data, { headers: headers });
	}

	public getUnfitReasonDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/editUnfitReason", data, { headers: headers });
	}

	public saveUnfitReasonDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/addNewReason", data, { headers: headers });
	}

	public addNewRank(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createRank", data, { headers: headers });
	}

	public addNewNationality(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createNationality", data, { headers: headers });
	}

	public addNewDepartment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createDepartment", data, { headers: headers });
	}

	public editVessel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/EditVessel", data, { headers: headers });
	}

	public editPONo(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/EditPoNo", data, { headers: headers });
	}

	public countryCodes() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: headers });
	}

	public restoreClinic(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreClinic/" + id + "/", {}, { headers: headers });
	}

	public restoreCertification(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreAcrediations/" + id + "/", {}, { headers: headers });
	}

	public restoreUser(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreClinicUsers/" + id + "/", {}, { headers: headers });
	}

	public restoreCompany(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreCompany/" + id + "/", {}, { headers: headers });
	}

	public restoreVessel(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreVessel/" + id + "/", {}, { headers: headers });
	}

	public restoreProfile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreProfile", data, { headers: headers });
	}

	public deleteTest(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteDocTest", data, { headers: headers });
	}

	public getTestExcludedDoctorAssigned(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestExcludedDoctorAssigned", data, { headers: headers });
	}

	public saveExtraTest(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveExtraTest", data, { headers: headers });
	}

	public saveTestForDoctor(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTestForDoctor", data, { headers: headers });
	}

	public createUpdateTest(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		if (data.id) {
			return this.httpClient.post(this.baseURL + "api/editDocTest", data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "api/createNewTest", data, { headers: headers });
		}
	}

	public getAllInvestigationData(data: any): Observable<any[]> {
		var token: any = localStorage.getItem('token');
		if (!token) {
			token = "Bearer " + environment.FIX_TOKEN;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		let response1 = this.httpClient.post(this.baseURL + "api/getInvestigation", data, { headers: headers });
		let response2 = this.httpClient.post(this.baseURL + "api/getQuetionsAndFamilyHistory", data, { headers: headers });
		let response3 = this.httpClient.post(this.baseURL + "api/getSystemicExaminationQuestions", data, { headers: headers });

		return forkJoin([response1, response2, response3]);
	}

	public getAllTestCategory() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/getAllTestCategory", { headers: headers });
	}

	public getDocTestById(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getDocTestById", data, { headers: headers });
	}

	public getTestByTestId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestByTestId", data, { headers: headers });
	}

	public getTestExcludedProfile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestListOtherThanProfile", data, { headers: headers });
	}

	public getOTP(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", "Bearer " + environment.FIX_TOKEN);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.get(this.baseURL + "api/login_user", { params: new HttpParams(httpParams), headers: headers });
	}

	public save_candans(data: any) {
		var token: any = localStorage.getItem('token');
		if (!token) {
			token = "Bearer " + environment.FIX_TOKEN;
		}
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.put(this.baseURL + "api/save_candans", data, { headers: headers });
	}

	public copyProfiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createProfileByOldRef", data, { headers: headers });
	}

	public getProfilesByClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getProfilesByClinic", data, { headers: headers });
	}

	public verifyImageOfUser(data: any) {
		return this.httpClient.post(this.baseURL + "api/verifyImageOfUser/", data);
	}

	public sendOTPForVerification(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", "Bearer " + environment.FIX_TOKEN);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/sendOTPForVerification/", data, { headers: headers });
	}

	public verifyOTPForLogin(data: any) {
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", "Bearer " + environment.FIX_TOKEN);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/verifyOTPForLogin/", data, { headers: headers });
	}

	public validateProfile(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/checkProfileByClinicAndProfileName", data, { headers: headers });
	}

	public deleteRank(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteRank", data, { headers: headers });
	}

	public createRank(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createRank", data, { headers: headers });
	}

	public restoreRank(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/restoreRank", data, { headers: headers });
	}

	public medicalProfileExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/medicalProfileExcel", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public getTestListWithCategoryAndDefaultValues(): Observable<any[]> {
		let data1: any = {
			ownerId: localStorage.getItem('OwnerId')
		}
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data1 } as HttpParamsOptions;
		let response1 = this.httpClient.get(this.baseURL + "api/getTestListWithCategory", { params: new HttpParams(httpParams), headers: headers });
		let response2 = this.httpClient.get(this.baseURL + "api/DoctorWiseDefaultValues", { params: new HttpParams(httpParams), headers: headers });
		return forkJoin([response1, response2]);
	}

	public getTestListWithCategory() {
		let data: any = {
			ownerId: localStorage.getItem('OwnerId')
		}
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTestListWithCategory", data, { headers: headers });
	}

	public saveDoctorWiseDefaultValues(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/DoctorWiseDefaultValues", data, { headers: headers });
	}

	public fetch_coordinates(params: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/fetch_coordinates_all", options);
	}

	public humanAnatomyAll1() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/fetch_coordinates_all", { headers: headers });
	}

	public humanAnatomyAll() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/humanAnatomy.json", { headers: headers });
	}

	public update_coordinates(params: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/save_body_points", params, { headers: headers });
	}

	public getRangeOfMotion(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getRangeOfMotionEvaluation", data, { headers: headers });
	}

	public saveRangeOfMotion(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveRangeOfMotionEvaluation", data, { headers: headers });
	}

	public updateAdditionalProfiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateAdditionalProfiles", data, { headers: headers });
	}

	public companyWiseAppointmentsInExcel(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/CompanyWiseAppointmentsInExcel", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public fetchTestsFromJSON(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/MachineIntegrationForHaematology", data, { headers: headers });
	}

	public candidateBillWithExtraTestPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateBillWithExtraTestPdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public candidateBillPdfwithalltest(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/candidateBillPdfwithalltest", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public saveAccount(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveAccount_DRSC", data, { headers: headers });
	}

	public deleteCandidatePicture(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/deleteCandidatePicture", data, { headers: headers });
	}

	public saveTreatment_drsc(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatment_drsc", data, { headers: headers });
	}

	public getAllWorkStatus() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allWorkStatus", { headers: headers });
	}

	public getAllResults() {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/allResults", { headers: headers });
	}

	public saveTreatmentWorkStatus(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentWorkStatus", data, { headers: headers });
	}

	public updateCaseClinic(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatmentClinic", data, { headers: headers });
	}

	public getTreatment_3cubenetwork(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/getTreatmentDetails_3cubenetwork", data, { headers: headers });
	}

	public saveTreatment_3cubenetwork(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveTreatment_3cubenetwork", data, { headers: headers });
	}

	public saveCaseBill(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/saveCaseBill", data, { headers: headers });
	}

	public sendMail(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/DRSCSendEmail", data, { headers: headers });
	}

	public getTempUnfitReport(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/ReportInWord", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public reportsToBedone(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		return this.httpClient.post(this.baseURL + "api/reportsToBedone", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public saveCandidateDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		if (token) {
			headers = headers.append("Authorization", token);
		}
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/SaveCandidateDetails", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public fetchCandidateDetails(id: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.get(this.baseURL + "api/FetchCandidateDetails?id=" + id, { headers: headers });
	}

	public uploadCandidatePictureOpen(data: any) {
		let headers = new HttpHeaders();
		return this.httpClient.post(this.baseURL + "api/UploadCandidateImage", data, { headers: headers });
	}

	public uploadCandidateSignOpen(data: any) {
		let headers = new HttpHeaders();
		return this.httpClient.post(this.baseURL + "api/UploadCandidateSign", data, { headers: headers });
	}

	public ApptCounts_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/ApptCounts_Company", data, { headers: headers });
	}

	public ApptRunRate_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/ApptRunRate_Company", data, { headers: headers });
	}

	public TopReasonsForUnfit_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/TopReasonsForUnfit_Company", data, { headers: headers });
	}

	public getAllClinicsCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/allClinic_company", data, { headers: headers });
	}

	public DoctorWiseAppointments(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/DoctorWiseAppointments", data, { headers: headers });
	}

	public getTopDoctors(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/TopDoctors", data, { headers: headers });
	}

	public ApptStatus_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptStatus_Company", data, { headers: headers });
	}

	public ApptByType_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ApptByType_Company", data, { headers: headers });
	}

	public showAllReasons_Company(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/showAllReasons_Company", data, { headers: headers });
	}

	public getAllAppointmentsOfCandidate(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/AllAppointmentsOfCandidate", data, { headers: headers });
	}

	public getTestDetailsOfAppointment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/TestDetailsOfAppointment", data, { headers: headers });
	}

	public sendRequestMail(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/sendCandidateDetailInInMail", data, { headers: headers });
	}

	public companyFileUpload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/companyFileUpload", data, { headers: headers });
	}

	public getCompanyFiles(params: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/companyFileUpload", options);
	}

	public deleteCompanyFiles(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/companyFileUpload", { params: new HttpParams(httpParams), headers: headers });
	}

	public billFileUpload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/billFileUpload", data, { headers: headers });
	}

	public billFileDelete(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/billFileDelete", data, { headers: headers });
	}

	public getClosureReportDetails(params: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/ClosureReport", options);
	}

	public saveClosureReportDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/ClosureReport", data, { headers: headers });
	}

	public getClosureReportPDF(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers, observe: 'response', responseType: 'arraybuffer' };
		return this.httpClient.get(this.baseURL + "api/ClosureReportPdf", options);
	}

	public getHCCaseDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/HCCaseDetails", options);
	}

	public saveHCCaseDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/HCCaseDetails", data, { headers: headers });
	}

	public createCity(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/createCity", data, { headers: headers });
	}

	public verifyReportPassword(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/ClosureReportPasswordCheck", data, { headers: headers }).toPromise();
	}

	public delete3CubeInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/InvoiceApi", { params: new HttpParams(httpParams), headers: headers });
	}

	public get3CubeInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/InvoiceApi", options);
	}

	public createUpdate3CubeInvoice(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		if (data.id) {
			return this.httpClient.put(this.baseURL + "api/InvoiceApi", data, { headers: headers });
		} else {
			return this.httpClient.post(this.baseURL + "api/InvoiceApi", data, { headers: headers });
		}
	}

	public invoicePDFDownload(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/invoice", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public updateInvoicePayment(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateInvoicePayment", data, { headers: headers });
	}

	public currencyList() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/currencies.json", { headers: headers });
	}

	public categories() {
		var headers = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
		return this.httpClient.get("./assets/files/categories.json", { headers: headers });
	}

	public getPrescriptionPdf(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/prescriptionpdf", data, { headers: headers, observe: 'response', responseType: 'arraybuffer' });
	}

	public casePrescriptionList(caseId: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: { caseId: caseId } } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/getPrescriptionsApi", options);
	}

	public addCaseMeds(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/prescriptionApi", data, { headers: headers });
	}

	public getCaseMeds(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/prescriptionApi", options);
	}

	public prefetchClosureReport(caseId: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: {caseId:caseId} } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/PrefetchClosureReport", options);
	}

	public treatmentInvoicePaidUnpaid(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.put(this.baseURL + "api/treatmentInvoiceList", data, { headers: headers });
	}

	public updateIsSpecialistConnected(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/updateIsSpecialistConnected", data, { headers: headers });
	}

	public getMissedAppointments(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/missedAppointments_shorecare", options);
	}

	public todaysAppointments_shorecare(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/todaysAppointments_shorecare", options);
	}

	public doctorsOrClinicnInShorecare(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/doctorsOrClinicnInShorecare", options);
	}

	public CategoryDetailsInShorecare(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/CategoryDetailsInShorecare", options);
	}

	public detailsInShorecare(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/detailsInShorecare", options);
	}

	public invoiceListByApptId(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/invoiceListByApptId", options);
	}

	public usdInvoiceFor3cube(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		return this.httpClient.post(this.baseURL + "api/usdInvoiceFor3cube", data, { headers: headers });
	}

	public deleteusdInvoiceFor3cube(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteusdInvoiceFor3cube", { params: new HttpParams(httpParams), headers: headers });
	}

	public updateRankByCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.put(this.baseURL + "api/updateRankByCaseId", data, { headers: headers });
	}

	public updateCompanyByCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.put(this.baseURL + "api/updateCompanyByCaseId", data, { headers: headers });
	}

	public updateVesselByCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.put(this.baseURL + "api/updateVesselByCaseId", data, { headers: headers });
	}

	public checkInvoiceDoneAgainstCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/checkInvoiceDoneAgainstCaseId", data, { headers: headers });
	}

	public deleteCubeReportByCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		return this.httpClient.delete(this.baseURL + "api/deleteCubeReportByCaseId", { params: new HttpParams(httpParams), headers: headers });
	}

	public CategoryDetailsInShorecareForCompany(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/CategoryDetailsInShorecareForCompany", data, { headers: headers });
	}

	public upcomingAppointments_shorecare(data:any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/upcomingAppointments_shorecare", options);
	}

	public getTreatmentList_3cubenetwork(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/CaseDetails", options);
	}

	public apptByCaseDetails(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/apptByCaseDetails", options);
	}

	public apptByCrewIdFromMHS(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/apptByCrewIdFromMHS", options);
	}

	public apptByCrewId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/apptByCrewId", options);
	}

	public apptDetailsForNetworkDr(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/apptDetailsForNetworkDr", options);
	}

	public updateTriageByCaseId(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/saveTriage", data, { headers: headers });
	}

	public saveCaseCategory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/saveCaseCategory", data, { headers: headers });
	}

	public allCaseCategory(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		headers = headers.append("Content-Type", 'application/json');
		headers = headers.append("Accept", 'application/json');
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options: any = { params: new HttpParams(httpParams), headers: headers };
		return this.httpClient.get(this.baseURL + "api/allCaseCategory", options);
	}

	public saveAddressAndZipCode(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/saveAddressAndZipCode", data, { headers: headers });
	}

	public getDistrictByZip(data: any) {
		var token: any = localStorage.getItem('token');
		let headers = new HttpHeaders();
		headers = headers.append("Authorization", token);
		return this.httpClient.post(this.baseURL + "api/getDistrictByZip", data, { headers: headers });
	}
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}
