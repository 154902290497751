import { FormControl } from "@angular/forms";

export const emailPattern = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,6}$/;

export function password_validation(control: FormControl) {
	let password: any = control.value;
	if (password == null || password == undefined) {
		password = "";
	}
	let errObj: any = {};
	if (password.length < 8) {
		errObj.minlengtherror = true;
	}
	const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
	if (!specialChars.test(password)) {
		errObj.specialcharerror = true;
	}
	const upper = /[A-Z]/;
	const lower = /[a-z]/;
	if (!upper.test(password)) {
		errObj.uppercaseerror = true;
	}
	if (!lower.test(password)) {
		errObj.lowercaseerror = true;
	}
	return errObj ? errObj : null;
}

export const flagList = [
	{ id: '5', name: "Hospitalised", class: "fa fa-square mr-sm text-primary" },
	{ id: '1', name: "New case", class: "fa fa-flag mr-sm text-black" },
	{ id: '4', name: "Physio-cases", class: "fa fa-flag mr-sm text-danger" },
	{ id: '2', name: "Follow-up", class: "fa fa-flag mr-sm text-warning" },
	{ id: '3', name: "Closed", class: "fa fa-flag mr-sm text-success" }
];