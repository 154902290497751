<form role="form" [ngStyle]="{'background-image':'url(../assets/img/bgs.jpg)','background-size': 'cover','height':'100%'}" (ngSubmit)="submitForm(user)">
	<div class="container container-sm animated fadeInDown">
		<div style="background-color: #e6eff5;margin-top: 100px;" class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
			<div class="text-center" style="margin-top: 30px;">
				<h3>Forgot Password?</h3>
			</div>
			<fieldset class="mt20">
				<div class="col-sm-12 form-group">
					<p style="text-decoration: underline;">Please enter your registered email address :-</p>
				</div>
				<div class="col-sm-12 form-group">
					<div class="input-group m-b">
						<span class="input-group-addon labelColor1"><i class="fa fa-envelope" aria-hidden="true"></i></span>
						<input type="email" id="user_email" placeholder="Enter your email address" name="email" id="email" class="form-control input_focus input-xs" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}" required/>
					</div>
				</div>
				<div class="col-sm-12 form-group mt-lg" *ngIf="errorshow || successshow">
					<div *ngIf="errorshow" role="alert" type="danger" class="alert alert-danger">
						<div class="text-center">
							<span class="ng-binding ng-scope">{{errorMsg}}</span>
						</div>
					</div>
					<div *ngIf="successshow" role="alert" type="danger" class="alert alert-info">
						<div class="text-center">
							<span class="ng-binding ng-scope">{{successMsg}}</span>
						</div>
					</div>
				</div>
				<div class="col-sm-12 form-group mt-lg text-center">
					<button id="btnSubmit" type="submit" class="btn btn-info" [disabled]="!user.email"><strong><i *ngIf="loadershow" class="fa fa-spinner fa-spin mr-sm" aria-hidden="true"></i>Send</strong></button>
				</div>
				<div class="col-sm-12 text-center text-black mt-lg" style="font-size: 11px;">&nbsp;&copy; {{year}}&nbsp;-&nbsp;<a href="http://3cubeservices.com/" target="_blank" class="text-black">3 Cube Services</a></div>
				<div class="col-sm-12 form-group text-center">
					<a href="mailto:info@3cubeservices.com" style="color:#000; font-weight:inherit; ">info@3cubeservices.com </a>
				</div>
			</fieldset>
		</div>
	</div>
</form>