import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { requiredFileType } from '../requiredFileType';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { emailPattern } from '../shared/constants';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
	moduleForm!: FormGroup;
	year: number = new Date().getFullYear();
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	checkCapsLock: boolean = false;
	loginFormErr: string = "";
	showOTPField = false;
	@ViewChild('bgVideo') bgVideo: ElementRef;
	
	constructor(private renderer: Renderer2, private toastr: ToastrService,private apiService: ApiService,private formBuilder: FormBuilder,private router: Router,private activeRoute: ActivatedRoute) {
		this.moduleForm = this.formBuilder.group({
			emailId: [null, [Validators.required]],
			otp: [""]
		});
	}

	ngAfterViewInit(): void {
		const media = this.bgVideo.nativeElement;
		this.renderer.setStyle(media, 'width', '100vw');
		this.renderer.setStyle(media, 'height', '100vh');
		this.renderer.setStyle(media, 'object-fit', 'cover');
		this.renderer.setStyle(media, 'position', 'fixed');
		this.renderer.setStyle(media, 'left', '0');
		this.renderer.setStyle(media, 'right', '0');
		this.renderer.setStyle(media, 'top', '0');
		this.renderer.setStyle(media, 'bottom', '0');
		this.renderer.setStyle(media, 'z-index', '-1');
		var isPlaying = media.currentTime > 0 && !media.paused && !media.ended && media.readyState > media.HAVE_CURRENT_DATA;
		if (!isPlaying) {
			media.muted = true;
			try {
				media.play();
			} catch (error) {}
		}
	}
	
	ngOnInit(): void {
		
	}
	
	submitForm() {
		if(this.moduleForm.invalid){
			this.toastr.warning("Please check required parameters", 'Warning',{
				tapToDismiss: true,
				closeButton: true,
				timeOut:10000
			});
			return;
		}
		if(!this.showOTPField){
			this.loadershow = true;
			this.apiService.sendOTPForVerification({emailId:this.f.emailId.value}).subscribe((data:any) => {
				this.loadershow = false;
				this.showOTPField = true;
				this.toastr.success("OTP sent to your email id", 'Success',{
					tapToDismiss: true,
					closeButton: true,
					timeOut:10000
				});
			},(error:any)=>{
				this.loadershow = false;
				this.loginErrorshow = true;
				this.loginFormErr = "Email-Id not found.";
				setTimeout(() => {
					this.loginErrorshow = false;
				}, 5000);
			});
		}else{
			this.loadershow = true;
			this.apiService.verifyOTPForLogin(this.moduleForm.value).subscribe((data:any) => {
				this.loadershow = false;
				if(data.Error){
					this.loginErrorshow = true;
					this.loginFormErr = "Invalid OTP";
					setTimeout(() => {
						this.loginErrorshow = false;
					}, 5000);
				}else{
					localStorage.setItem('token', "Bearer "+data["token"]);
					localStorage.setItem('FirstName', data["FirstName"]);
					localStorage.setItem('LastName', data["LastName"]);
					localStorage.setItem('emailId', data["emailId"]);
					localStorage.setItem('isSuperAdmin', data["isSuperAdmin"]);
					localStorage.setItem('UserAccess', data["UserAccess"]);
					localStorage.setItem('DoctAccess', data["DoctAccess"]);
					localStorage.setItem('OwnerId', data["OwnerId"]);
					localStorage.setItem('ownerName', data["ownerName"]);
					if(data["clinic"]){
						localStorage.setItem('clinic', data["clinic"]);
						localStorage.setItem("centerId",data["clinic"]);
						localStorage.setItem('centerName', data["clinic_name"]);
						this.apiService.updateClinicName(data["clinic_name"]);
					}
					this.toastr.success("Logged in successfully", 'Success',{
						tapToDismiss: true,
						closeButton: true,
						timeOut:3000
					});
					this.gotoDashboard();
				}
			},(error:any)=>{
				this.loadershow = false;
				this.loginErrorshow = true;
				this.loginFormErr = "Invalid OTP";
				setTimeout(() => {
					this.loginErrorshow = false;
				}, 5000);
			});
		}
		
	}

	gotoDashboard(){
		if(localStorage.getItem("isSuperAdmin")=="true"){
			this.router.navigate(['/app/admin-dashboard']);
		}else{
			this.router.navigate(["/app/"+this.apiService.getDashboardURL()]);
		}
	}

	get f() { return this.moduleForm.controls; }
}
