import { Component, OnInit, NgZone } from '@angular/core';
import { ApiService } from '../api.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-recover',
	templateUrl: './recover.component.html',
	styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {
	user: any = { email: '' };
	year: number = new Date().getFullYear();
	loadershow: boolean = false;
	errorshow: boolean = false;
	successshow: boolean = false;
	errorMsg: string = "";
	successMsg: string = "";
	
	constructor(private toastr: ToastrService,private apiService: ApiService,private router: Router,private zone: NgZone) {}
	
	ngOnInit(): void {
		
	}
	
	submitForm(user: any) {
		this.loadershow = true;
		var url = window.location.href;
		var arr = url.split("/");
		var result = arr[0] + "//" + arr[2];
		let payload = {username:user.email,domain:result};
		this.apiService.sendResetPasswordLink(payload).subscribe((data:any) => {
			this.loadershow = false;
			this.successshow = true;
			this.successMsg = "Link sent to your registered email address.";
			setTimeout(() => {
				this.successshow = false;
			}, 10000);
			this.toastr.info("Link sent.", 'Success!!!',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		},(error:any)=>{
			this.loadershow = false;
			this.errorshow = true;
			this.errorMsg = error.error;
			setTimeout(() => {
				this.errorshow = false;
			}, 5000);
		}
		);
	}
}