import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output} from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { emailPattern } from 'src/app/shared/constants';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import {SignaturePad} from 'angular2-signaturepad';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
	selector: 'app-candidate-details',
	templateUrl: './candidate-details.component.html',
	styleUrls: ['./candidate-details.component.css']
})
export class CandidateDetailsComponent implements OnInit {
	pagetitle = "Candidate Details";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	pageLoading: boolean = false;
	customError:any = {};
	screenHeight = screen.height - 200;
	logourl = "../assets/img/clip(white).png";
	year: number = new Date().getFullYear();
	maxDOB = new Date();
	countries:any = [];
	nationalityList: any = [];
	step = 1;
	questionnaireError = false;
	detailsSaved = false;
	questions1:any[] = [
		{que_id:'1',question:"Severe one-sided headaches",answer:"0"},
		{que_id:'2',question:"Head Injury / Concussion",answer:"0"},
		{que_id:'4',question:"Fits / Epilepsy",answer:"0"},
		{que_id:'5',question:"Dizziness / Fainting",answer:"0"},
		{que_id:'6',question:"Eye / Vision Problems(Glasses etc)",answer:"0"},
		{que_id:'7',question:"Hearing Impairment",answer:"0"},
		{que_id:'8',question:"Ear / Nose / Throat problem",answer:"0"},
		{que_id:'9',question:"Stomach / Bowel disorders",answer:"0"},
		{que_id:'10',question:"Gall Stones",answer:"0"},
		{que_id:'11',question:"Kidney disorders",answer:"0"},
		{que_id:'12',question:"Jaundice",answer:"0"},
		{que_id:'13',question:"Liver Disease",answer:"0"},
		{que_id:'14',question:"Piles",answer:"0"},
		{que_id:'15',question:"Varicose veins",answer:"0"},
		{que_id:'16',question:"Blood Disorder",answer:"0"},
		{que_id:'37',question:"Signed off on medical grounds/Declared Unfit",answer:"0"},
		{que_id:'918',question:"Oral / Dental / Tooth disorder",answer:"0"},
		{que_id:'18',question:"Hernia",answer:"0"},
		{que_id:'19',question:"Hydrocoele",answer:"0"},
	];
	questions2:any[] = [
		{que_id:'20',question:"Appendicitis",answer:"0"},
		{que_id:'21',question:"High blood pressure",answer:"0"},
		{que_id:'22',question:"Low blood pressure",answer:"0"},
		{que_id:'23',question:"Heart disease",answer:"0"},
		{que_id:'24',question:"Asthma",answer:"0"},
		{que_id:'25',question:"Bronchitis",answer:"0"},
		{que_id:'26',question:"Tuberculosis",answer:"0"},
		{que_id:'27',question:"Allergy",answer:"0"},
		{que_id:'28',question:"Skin disease",answer:"0"},
		{que_id:'29',question:"Infection / Contagious disease",answer:"0"},
		{que_id:'30',question:"Adiction to alcohol / drugs / tobaco",answer:"0"},
		{que_id:'31',question:"Fracture / Dislocation / Injury",answer:"0"},
		{que_id:'85',question:"Amputation",answer:"0"},
		{que_id:'32',question:"Major / Minor Operation",answer:"0"},
		{que_id:'33',question:"Diabetes",answer:"0"},
		{que_id:'34',question:"Nervous / Mental disease",answer:"0"},
		{que_id:'35',question:"Sleep disorder",answer:"0"},
		{que_id:'36',question:"Malignant Disease(Cancer)",answer:"0"},
	];
	qrURL:any = null;
	showWebcam = false;
	isCameraExist = true;
	showDropBox = false;
	errors: WebcamInitError[] = [];
	private trigger: Subject<void> = new Subject<void>();
	private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
	allowedFiles1 = ['.jpg', '.jpeg', '.png'];
	fileMsg: any[] = [];
	uploadedFiles:any = [];
	imageUploadLoader:boolean = false;
	media_url:any = null;
	@ViewChild("signaturePad") signaturePad: SignaturePad;
	signaturePadOptions: Object = {
		'minWidth': 5,
		'canvasWidth': 400,
		'canvasHeight': 200
	};
	showImageCapture = false;
	showSignCapture = false;
	
	constructor(private sanitizer: DomSanitizer,private toastr: ToastrService,private apiService: ApiService,private formBuilder: FormBuilder,private router: Router,private activeRoute: ActivatedRoute) { }
	
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.moduleForm = this.formBuilder.group({
				pass_no: [null, [Validators.required]],
				indos_no: [null],
				f_name: [null, [Validators.required]],
				m_name: [null],
				l_name: [null, [Validators.required]],
				sex: [null, [Validators.required]],
				dob: [null, [Validators.required]],
				cdc: [null],
				employe_id: [null],
				cc_no: [null],
				tel_no: [null],
				h_add: [null],
				email: [null, [Validators.required,Validators.pattern(emailPattern)]],
				sob: [null],
				Nationality: [84, [Validators.required]],
				img_url: [null],
				digi_sign: [null],
				longitude: [null],
				latitude: [null],
			});
			this.getCountryCodes();
			this.getAllNationality();
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position)=>{
					this.f.longitude.setValue(position.coords.longitude);
					this.f.latitude.setValue(position.coords.latitude);
				},(error)=>{
					console.log(error);
				});
			} else {
				console.log("No support for geolocation");
			}
		});
	}
	
	get f() { return this.moduleForm.controls; }
	
	next(){
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.step++;
	}
	previous(){
		this.step--;
	}
	
	getCountryCodes(){
		this.apiService.countryCodes().subscribe((data:any) => {
			this.countries = data;
		},(error)=>{
			this.countries = [];
		});
	}
	
	getAllNationality() {
		this.apiService.getAllNationality().subscribe((data: any) => {
			this.nationalityList = data.filter((n:any)=>!n.disabled);
		}, (error) => {
			this.nationalityList = [];
			if (error.status == 401) { return }
		});
	}
	
	saveData(){
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let ques:any = {};
		for (let i = 0; i < this.questions1.length; i++) {
			if(this.questions1[i].answer==null){
				this.toastr.info("Please answer all questions.", 'Warning',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				return;
			}
			ques[this.questions1[i].que_id] = this.questions1[i].answer;
		}
		for (let i = 0; i < this.questions2.length; i++) {
			if(this.questions2[i].answer==null){
				this.toastr.info("Please answer all questions.", 'Warning',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
				return;
			}
			ques[this.questions2[i].que_id] = this.questions2[i].answer;
		}
		let data:any = {
			pass_no: this.f.pass_no.value,
			indos_no: this.f.indos_no.value,
			f_name: this.f.f_name.value,
			m_name: this.f.m_name.value,
			l_name: this.f.l_name.value,
			sex: this.f.sex.value,
			dob: this.getDateStringFromDate(this.f.dob.value),
			cdc: this.f.cdc.value,
			employe_id: this.f.employe_id.value,
			cc_no: this.f.cc_no.value,
			tel_no: this.f.tel_no.value,
			h_add: this.f.h_add.value,
			email: this.f.email.value,
			sob: this.f.sob.value,
			Nationality: this.f.Nationality.value,
			img_url: this.f.img_url.value,
			digi_sign: this.f.digi_sign.value,
			longitude: this.f.longitude.value,
			latitude: this.f.latitude.value,
			CandAns:JSON.stringify(ques)
		}
		this.showLoader = true;
		this.apiService.saveCandidateDetails(data).subscribe((data:any) => {
			this.showLoader = false;
			this.detailsSaved = true;
			this.toastr.success("Details saved successfully", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			var file = new Blob([data.body], {type: 'image/png'});
			this.qrURL = this.getSafeUrl(URL.createObjectURL(file));
			/* this.router.navigateByUrl('/refresh', {skipLocationChange: true}).then(() => {
				this.router.navigate(['/app/candidate-details']);
			}); */
		},(error:any) => {
			this.showLoader = false;
			this.toastr.error(error.error, 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
			/* let errorMsg = error.error;
			if(typeof errorMsg=='object'){
				for (const [key, value] of Object.entries(errorMsg)) {
					let val:any = value;
					if(val[0]=="This field must be unique."){
						val[0] = "Already exists";
					}
					if(this.moduleForm.controls[key]){
						this.moduleForm.controls[key].setErrors({incorrect:true});
						this.customError[key] = val[0];
						let selector = "#"+key;
						try {
							$('html, body').animate({
								scrollTop: $(selector).offset().top - 60 + 'px'
							}, 'fast');
						} catch (error) {}
						$(selector).focus();
					}else{
						this.toastr.error(key+":"+val[0], 'Error',{
							timeOut:3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				}
				return;
			}
			this.toastr.error(errorMsg, 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			}); */
		});
	}
	
	getSafeUrl(url:any) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url)
	}
	
	getDateStringFromDate(date: any) {
		if (date == "" || date == null || date == undefined) {
			return null;
		}
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var dayStr = day < 10 ? '0' + day : day;
		var monthStr = month < 10 ? '0' + month : month;
		var fromStr = year + "-" + monthStr + "-" + dayStr;
		return fromStr;
	}
	
	customSearchFn(term: string, item: any) {
		term = term.toLocaleLowerCase();
		if(item.dial_code!=null && item.name!=null){
			return item.dial_code.toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1;
		}else if(item.dial_code!=null){
			return item.dial_code.toLocaleLowerCase().indexOf(term) > -1;
		}else{
			return item.name.toLocaleLowerCase().indexOf(term) > -1
		}
	}
	
	scrollToTop(){
		let selector = "#page-content-container-common";
		try {
			$('html, body').animate({
				scrollTop: $(selector).offset().top+ 'px'
			}, 'slow');
		} catch (error) {}
	}
	
	showScrollBtn = false;
	@HostListener('window:scroll', ['$event'])
	onScroll(e:any) {
		try {
			if ($('#main-menu-header').offset().top) {
				this.showScrollBtn = true;
			} else {
				this.showScrollBtn = false;
			}
		} catch (error) {}
	}
	
	////////////////////////////
	openCaptureImage(){
		this.showImageCapture = true;
		WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
			this.isCameraExist = mediaDevices && mediaDevices.length > 0;
			this.showWebcam = true;
		});
	}
	
	clearCaptureImage(){
		this.showImageCapture = false;
		this.showWebcam = false;
		this.showDropBox = false;
	}
	
	takeSnapshot(): void {
		this.trigger.next();
		this.showDropBox=false;
	}
	
	onOffWebCame() {
		this.showWebcam = !this.showWebcam;
	}
	
	handleInitError(error: WebcamInitError) {
		this.errors.push(error);
	}
	
	changeWebCame(directionOrDeviceId: boolean | string) {
		this.nextWebcam.next(directionOrDeviceId);
	}
	
	handleImage(webcamImage: WebcamImage) {
		this.urltoFile(webcamImage.imageAsDataUrl, 'image.jpeg','image/jpeg').then((file)=>{
			const formData = new FormData();
			formData.append('img_url', file);
			this.uploadFile_CandPic(formData);
		});
	}
	
	get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}
	
	get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}
	
	urltoFile(url:string, filename:string, mimeType:string){
		return fetch(url).then((res)=>{
			return res.arrayBuffer();
		}).then((buf)=>{
			return new File([buf], filename,{type:mimeType});
		})
	}
	
	public onFileSelect_CandPic(files: NgxFileDropEntry[]) {
		this.fileMsg = [];
		for (let i = 0;i < files.length;i++) {
			const droppedFile = files[i];
			if (droppedFile.fileEntry.isFile && this.isFileAllowed_CandPic(droppedFile.fileEntry.name)) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					this.fileMsg.push({msg:droppedFile.fileEntry.name,isInvalid:false});
					if(file.type=="image/jpeg" || file.type=="image/png"){
						let _URL = window.URL || window.webkitURL;
						let img = new Image();
						var objectUrl = _URL.createObjectURL(file);
						img.onload = () => {
							_URL.revokeObjectURL(objectUrl);
							const formData = new FormData();
							formData.append('img_url', file);
							this.uploadFile_CandPic(formData);
						};
						img.src = objectUrl;
					}else{
						const formData = new FormData();
						formData.append('img_url', file);
						this.uploadFile_CandPic(formData);
					}
				});
			} else {
				this.fileMsg.push({msg:droppedFile.fileEntry.name+". Only "+this.allowedFiles1.toString()+" files are allowed.",isInvalid:true});
			}
		}
	}
	
	isFileAllowed_CandPic(fileName: string) {
		let isFileAllowed = false;
		const regex = /(?:\.([^.]+))?$/;
		const extension = regex.exec(fileName);
		if (undefined !== extension && null !== extension) {
			for (const ext of this.allowedFiles1) {
				if (ext === extension[0]) {
					isFileAllowed = true;
				}
			}
		}
		return isFileAllowed;
	}
	
	uploadFile_CandPic(formData:FormData){
		this.imageUploadLoader = true;
		this.apiService.uploadCandidatePictureOpen(formData).subscribe((data:any) => {
			this.media_url = data.s3path;
			this.f.img_url.setValue(data.img_url);
			this.showWebcam = false;
			this.showDropBox = false;
			this.imageUploadLoader = false;
			this.showImageCapture = false;
			this.toastr.success("File uploaded successfully.", 'Success',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		},(error:any) => {
			this.imageUploadLoader = false;
			if(error.status==401){return}
			this.toastr.error("Something went wrong", 'Error',{
				timeOut:3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}
	
	showDropBoxLflag(){
		this.showDropBox = true;
		this.showWebcam = !this.showWebcam;
	}
	
	openSignatureModal(){
		this.showSignCapture = true;
		setTimeout(() => {
			this.signaturePad.clear();
			setTimeout(() => {
				this.signaturePad.set('canvasWidth', $("#signature-container").width());
			}, 200);
		}, 200);
		
	}
	
	clearsignatureModal(){
		this.signaturePad.clear();
		this.showSignCapture = false;
	}
	
	takeSignature(){
		this.urltoFile(this.signaturePad.toDataURL(), 'sign.png','image/png').then((file)=>{
			const formData = new FormData();
			formData.append('digi_sign', file);
			this.apiService.uploadCandidateSignOpen(formData).subscribe((data:any) => {
				this.media_url = data.s3path;
				this.f.digi_sign.setValue(data.digi_sign);
				this.showSignCapture = false;
			},(error) => {
				if(error.status==401){return}
				this.toastr.error("Unable to save signature.", 'Error',{
					timeOut:3000,
					tapToDismiss: true,
					closeButton: true
				});
			});
			
		});
	}
	
	drawComplete() {}
	
	drawStart() {}
	
	clearSign() {
		this.signaturePad.clear();
	}
}
