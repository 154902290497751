import { Injectable } from '@angular/core';
import {DefaultUrlSerializer, RouterModule, Routes, UrlSegment, UrlSerializer, UrlTree} from '@angular/router';
@Injectable()
export class UrlSerializerService {
	private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();
	parse(url: string): UrlTree {
		/* while(url.indexOf("=")>-1){
			url = url.replace("=","");
		} */
		return this.defaultSerializer.parse(url);
		/*const lastSlashIndex = url.lastIndexOf('/'), equalSignIndex = url.indexOf('=', lastSlashIndex);
		if (equalSignIndex > -1) {
			const keyValArr = url.substr(lastSlashIndex + 1).split('=');
			const urlTree = this.defaultSerializer.parse(url);
			urlTree.root.children['primary'].segments.forEach((segment: UrlSegment) => {
				if (segment.path === keyValArr[0]) {
					segment.path = keyValArr.join('');
				}
			});
			return urlTree;
		} else {
			return this.defaultSerializer.parse(url);
		}*/
	}

	serialize(tree: UrlTree): string {
		return this.defaultSerializer.serialize(tree);
	}

}