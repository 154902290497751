import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
	
	transform(items: any[], searchText: string, fieldName: string): any[] {
		if (!items) { return []; }
		if (!searchText) { return items; }
		searchText = searchText.toLowerCase();
		return items.filter(item => {
			if (item && item[fieldName]) {
				return item[fieldName].toLowerCase().includes(searchText);
			}
			return false;
		});
	}
}