<header id="main-menu-header" style="background-color: #2494f2 !important;position: fixed;width: 100%;z-index: 410;box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);">
	<nav role="navigation" class="navbar topnavbar">
		<div class="navbar-header collapse navbar-collapse">
			<a class="navbar-brand mr0 ml0 pr0 pl20" style="padding-top: 8px;padding-bottom: 8px;pointer-events: none;" id="headNav_linkDashboard">
				<img src="{{logourl}}" />
			</a>
		</div>
		<div class="mobile-menu" style="color: white;">
			<div class="row">
				<div class="col-sm-3 col-xs-3">
					<a class="mr0 ml0 pr0 pl20" style="padding-top: 8px;padding-bottom: 8px;pointer-events: none;" id="headNav_linkDashboard">
						<img src="{{logourl}}" style="width: 75px;margin-top: 3px;"/>
					</a>
				</div>
			</div>
		</div>
	</nav>
</header>
<section class="content" [ngStyle] = "{'min-height.px': screenHeight}" id="page-content-container-common" style="padding: 60px 10px 10px 10px;">
	<div class="row" *ngIf="!detailsSaved"> 
		<div class="col-md-12">
			<form [formGroup]="moduleForm" class="msform" #modform="ngForm">
				<ul id="progressbar" style="text-align: center;">
					<li [ngClass]="step>=1 ? 'active ' : 'inactive'" style="color: black;"><h5>{{pagetitle}}</h5></li>
					<li [ngClass]="step>=2 ? 'active' : 'inactive'" style="color: black;"><h5>Photo & Signature</h5></li>
					<li [ngClass]="step>=3 ? 'active' : 'inactive'" style="color: black;"><h5>Questionnaire</h5></li>
				</ul>
				<fieldset  *ngIf="step == 1" >
					<h2 class="fs-title">Step 1: {{pagetitle}}</h2>
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>First Name:</label>
							<input type="text" id="f_name" name="f_name" formControlName="f_name" placeholder="First Name" class="form-control" />
							<small *ngIf="f.f_name.invalid && (modform.submitted || f.f_name.touched || f.f_name.dirty)" style="color: maroon;">
								<span *ngIf="f.f_name.errors?.required"> Please enter first name.
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label>Middle Name:</label>
							<input type="text" id="m_name" name="m_name" formControlName="m_name" placeholder="Middle Name" class="form-control" />
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Last Name:</label>
							<input type="text" id="l_name" name="l_name" formControlName="l_name" placeholder="Last Name" class="form-control" />
							<small *ngIf="f.l_name.invalid && (modform.submitted || f.l_name.touched || f.l_name.dirty)" style="color: maroon;">
								<span *ngIf="f.l_name.errors?.required">
									Please enter last name.
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Email Id:</label>
							<input type="text" id="email" name="email" formControlName="email" placeholder="Email Id" class="form-control" />
							<small *ngIf="f.email.invalid && (modform.submitted || f.email.touched || f.email.dirty)" style="color: maroon;">
								<span *ngIf="f.email.errors?.required">
									Please enter email Id.
								</span>
								<span *ngIf="f.email.errors?.pattern">
									Please enter valid email! Eg. demo&#64;mail.com
								</span>
							</small>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Gender:</label>
							<div class="col-md-12 radio-inline c-radio">
								<label class="col-lg-6 col-sm-6">
									<input type="radio" [name]="'sex'" [value]="'Male'" formControlName="sex" />
									<span class="fa fa-male"></span>Male
								</label>
								<label class="col-lg-6 col-sm-6">
									<input type="radio" [name]="'sex'" [value]="'Female'" formControlName="sex" />
									<span class="fa fa-female"></span>Female
								</label>
							</div>
							<div class="col-md-12">
								<small *ngIf="f.sex.invalid && (modform.submitted || f.sex.touched || f.sex.dirty)" style="color: maroon;">
									<span *ngIf="f.sex.errors?.required">
										Please select gender.
									</span>
								</small>
							</div>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Date of Birth:</label><br />
							<kendo-datepicker style="width: 100%;" format="dd-MM-yyyy" formControlName="dob" [placeholder]="'dd-MM-yyyy'" [max]="maxDOB">
							</kendo-datepicker>
							<small *ngIf="f.dob.invalid && (modform.submitted || f.dob.touched || f.dob.dirty)" style="color: maroon;">
								<span *ngIf="f.dob.errors?.required">
									Please enter date of birth.
								</span>
								<span *ngIf="f.dob.errors?.maxError">
									Please enter valid date of birth.
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label>Place Of Birth:</label>
							<input type="text" id="sob" name="sob" formControlName="sob" placeholder="Place Of Birth" class="form-control" />
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span>Nationality:</label>
							<ng-select appendTo="body" id="Nationality" name="Nationality" formControlName="Nationality" [items]="nationalityList" bindLabel="nationality_name" bindValue="id" [clearable]="false" placeholder="Select Nationality">
							</ng-select>
							<small *ngIf="f.Nationality.invalid && (modform.submitted || f.Nationality.touched || f.Nationality.dirty)" style="color: maroon;">
								<span *ngIf="f.Nationality.errors?.required">
									Please select Nationality.
								</span>
							</small>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label><span style="color: #b40a09;">*&nbsp;</span> Passport No.:</label>
							<input type="text" id="pass_no" name="pass_no" formControlName="pass_no" placeholder="Passport No." class="form-control" />
							<small *ngIf="f.pass_no.invalid && (modform.submitted || f.pass_no.touched || f.pass_no.dirty)" style="color: maroon;">
								<span *ngIf="f.pass_no.errors?.required">
									Please enter passport number.
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label>Indos No.:</label>
							<input type="text" id="indos_no" name="indos_no" formControlName="indos_no" placeholder="Indos No." class="form-control" />
							<small *ngIf="f.indos_no.invalid && (modform.submitted || f.indos_no.touched || f.indos_no.dirty)" style="color: maroon;">
								<span *ngIf="f.indos_no.errors?.required">
									Please enter indos number.
								</span>
							</small>
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label>CDC No.:</label>
							<input type="text" id="cdc" name="cdc" formControlName="cdc" placeholder="CDC No" class="form-control" />
						</div>
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label>Crew Id:</label>
							<input type="text" id="employe_id" name="employe_id" formControlName="employe_id" placeholder="Crew Id" class="form-control" />
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<label class="col-sm-12 pl0 pr0">Mobile No.:</label>
							<div class="col-sm-4 pl0 pr0">
								<ng-select appendTo="body" [items]="countries" id="cc_no" bindLabel="name" bindValue="dial_code" [searchFn]="customSearchFn" [clearable]="false" placeholder="Country Code" name="cc_no" formControlName="cc_no">
									<ng-template ng-label-tmp let-item="item">
										{{item.dial_code}}
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
										{{item.dial_code}}<br>{{item.name}}
									</ng-template>
								</ng-select>
							</div>
							<div class="col-sm-8 pl0 pr0">
								<input type="text" id="tel_no" name="tel_no" formControlName="tel_no" placeholder="999 999 9999" class="form-control" />
							</div>
						</div>
						<div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
							<label>Address:</label>
							<textarea id="h_add" name="h_add" formControlName="h_add" placeholder="Address" class="form-control"></textarea>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<p *ngIf="modform.invalid && modform.submitted" style="color: maroon;">
								Please fill the required details and click next.
							</p>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<button type="submit" class="btn btn-sm btn-success pull-right" (click)="next()">Next</button>
						</div>
					</div>
				</fieldset>
			</form>
			<form class="msform">
				<fieldset *ngIf="step == 2">
					<h2 class="fs-title">Step 2: Photo & Signature</h2>
					<div class="row mb-lg">
						<div *ngIf="!showImageCapture" class="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-lg-offset-4 col-md-offset-4 col-sm-offset-3 col-xs-offset-3 text-center">
							<header style="text-align: center;border: 2px solid #555;">
								<div class="my-container">
									<img class="image" [src]="f?.img_url.value ? media_url+'/'+f?.img_url.value : '../assets/img/unknown_user.png'" style="width:100%;height: auto;" />
									<div class="middle">
										<div class="my-text"> 
											<a class="info" id="start-camera" (click)="openCaptureImage()">
												<i class="fa fa-camera"></i> Change Photo
											</a>
										</div>
									</div>
								</div>
							</header>
						</div>
						<div *ngIf="showImageCapture" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div>
								<div>
									<div *ngIf="isCameraExist && showDropBox==false; else noCameraExist">
										<div style="text-align:center">
											<webcam *ngIf="showWebcam" [height]="300" [width]="400" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" [switchCamera]="nextWebcamObservable"></webcam>
										</div>
										<div *ngIf="errors.length > 0">
											<h4>Error Messages:</h4>
											<ul *ngFor="let error of errors">
												<li>{{ error | json }}</li>
											</ul>
										</div>
									</div>
									<div *ngIf="showDropBox">
										<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="onFileSelect_CandPic($event)">
											<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
												<div style="text-align: center;">
													<label>Drop files here</label><br>
													<label>OR</label><br>
													<button type="button" class="btn btn-info btn-sm" (click)="openFileSelector()">Browse Files</button>
												</div>
											</ng-template>
										</ngx-file-drop>
										<p *ngFor="let msg of fileMsg;let i = index;" style="padding-left: 30px;">
											<span [ngStyle]="{'color':msg.isInvalid?'maroon':''}">
												{{msg.msg}}
											</span>
										</p>
									</div>
									<ng-template #noCameraExist>
										<div *ngIf="showDropBox==false">
											Camera device not available
										</div>
									</ng-template>
								</div>
								<div>
									<button class="btn btn-sm btn-info mr-sm" (click)="takeSnapshot()"><i class="fa fa-camera"></i> Capture</button>
									<button class="btn btn-sm btn-info mr-sm" (click)="showDropBoxLflag()"><i class="fa fa-upload"></i> Upload Picture</button>
									<button class="btn btn-sm btn-default" (click)="clearCaptureImage()">Cancel</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-lg">
						<div *ngIf="!showSignCapture" class="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-lg-offset-4 col-md-offset-4 col-sm-offset-3 col-xs-offset-3 text-center">
							<header style="text-align: center;border: 2px solid #555;">
								<div class="my-container">
									<img *ngIf="f?.digi_sign.value" [src]="media_url+'/'+f?.digi_sign.value" style="width:100%;height: auto;" />
									<p *ngIf="!f?.digi_sign.value" style="width:100%;height: auto;">Signature</p>
									<div class="middle">
										<div class="my-text"> 
											<a class="info" id="start-signature" (click)="openSignatureModal()">
												<i class="fa fa-pencil"></i> Change Signature
											</a>
										</div>
									</div>
								</div>
							</header>
						</div>
						<div *ngIf="showSignCapture" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<div>
								<div>
									<div class="row">
										<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
											<table class="table table-bordered table-stripped">
												<tbody>
													<tr>
														<td colspan="2">
															<label class="control-label">Sign Here <i style="cursor: pointer;" matTooltip="Clear" class="fa fa-refresh ml" (click)="clearSign()"></i></label>
															<div id="signature-container">
																<signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
															</div>
															<p><strong>Signature Of Applicant</strong></p>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div>
									<button class="btn btn-sm btn-info mr-sm" (click)="takeSignature()">Upload</button>
									<button class="btn btn-sm btn-default" (click)="clearsignatureModal()">Cancel</button>
								</div>
							</div>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<button type="button" class="btn btn-sm btn-success pull-right" (click)="next()">Next</button>
							<button type="button" class="btn btn-sm btn-info pull-right mr-sm" (click)="previous()">Previous</button>
						</div>
					</div>
				</fieldset>
			</form>
			<form class="msform">
				<fieldset *ngIf="step == 3">
					<h2 class="fs-title">Step 3: Questionnaire</h2>
					<div class="row">
						<table class="table table-bordered table-stripped">
							<thead>
								<tr>
									<th style="background-color: #2494f2;color: white;text-align: center;">Is there any past/present history of the following:</th>
								</tr>
							</thead>
						</table>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
							<table class="table table-bordered table-stripped">
								<tbody>
									<ng-container *ngFor="let ques of questions1">
										<tr>
											<td style="width: 50%;">{{ques.question}}</td>
											<td style="width: 25%;">
												<div class="radio-inline c-radio">
													<label>
														<input type="radio" [name]="'cand_ques'+ques.que_id" [value]="'1'" [(ngModel)]="ques.answer" [ngModelOptions]="{standalone: true}" />
														<span class="fa fa-check"></span>Yes
													</label>
												</div>
											</td>
											<td style="width: 25%;">
												<div class="radio-inline c-radio">
													<label>
														<input type="radio" [name]="'cand_ques'+ques.que_id" [value]="'0'" [(ngModel)]="ques.answer" [ngModelOptions]="{standalone: true}" />
														<span class="fa fa-times"></span>No
													</label>
												</div>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
							<table class="table table-bordered table-stripped">
								<tbody>
									<ng-container *ngFor="let ques of questions2">
										<tr>
											<td style="width: 50%;">{{ques.question}}</td>
											<td style="width: 25%;">
												<div class="radio-inline c-radio">
													<label>
														<input type="radio" [name]="'cand_ques'+ques.que_id" [value]="'1'" [(ngModel)]="ques.answer" [ngModelOptions]="{standalone: true}" />
														<span class="fa fa-check"></span>Yes
													</label>
												</div>
											</td>
											<td style="width: 25%;">
												<div class="radio-inline c-radio">
													<label>
														<input type="radio" [name]="'cand_ques'+ques.que_id" [value]="'0'" [(ngModel)]="ques.answer" [ngModelOptions]="{standalone: true}" />
														<span class="fa fa-check"></span>No
													</label>
												</div>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
							<p *ngIf="questionnaireError" style="color: maroon;">
								Please answer all questions and submit.
							</p>
						</div>
					</div>
					<div class="row mb-lg">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<button type="button" [disabled]="showLoader || !modform.dirty" id="btnSubmit" (click)="saveData()" class="btn btn-sm btn-success pull-right">
								<span class="mr-sm">
									<i *ngIf="showLoader" aria-hidden="true" class="fa fa-spinner fa-spin"></i>
									<i *ngIf="!showLoader" aria-hidden="true" class="fa fa-save"></i>
								</span>
								<span>Submit</span>
							</button>
							<button type="button" class="btn btn-sm btn-info pull-right mr-sm" (click)="previous()">Previous</button>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
	<div class="row" *ngIf="detailsSaved">
		<div class="col-sm-12">
			<div class="panel panel-default">
				<div class="panel-body text-center" *ngIf="qrURL">
					<h4>Thank you!!!</h4>
					<h5>Please show this QR code at medical center.</h5>
					<img [src]="qrURL" />
				</div>
			</div>
		</div>
	</div>
</section>
<a *ngIf="showScrollBtn" (click)="scrollToTop()" id="scrollToTopButton" matTooltip="Scroll to top">
	<i class="fa fa-angle-up" aria-hidden="true" style="font-size: 15px;"></i>
</a>
<div class="text-center">
	<strong>Powered By<a id="headNav_link_3cube_site" href="http://3cubeservices.com/" target="_blank">3CUBE</a> © {{year}}</strong>
</div>
